body {
   margin: 0;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   font-family: "Noto Sans", sans-serif;
}

.pdBkgnd {
   background-image: linear-gradient(
      135deg,
      #333 6.25%,
      #222 6.25%,
      #222 50%,
      #222 50%,
      #333 56.25%,
      #222 56.25%,
      #222 100%
   ) !important;
   background-size: 11.31px 11.31px !important;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   min-height: 100vh;
   min-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
   font-family: "Noto Sans", sans-serif !important;
   font-weight: 700;
}

* {
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: -moz-none;
   -o-user-select: none;
   user-select: none;
}

.noScroll {
   overflow: hidden;
}

input {
   -webkit-user-select: text;
   -khtml-user-select: text;
   -moz-user-select: text;
   -o-user-select: text;
   user-select: text;
}

img {
   pointer-events: none;
}

.transparentBG {
   background: url("./images/transparentbg-light.jpg") top left repeat;
   background-size: 100px;
}

.theme-dark {
   background-image: linear-gradient(
      135deg,
      #333 6.25%,
      #222 6.25%,
      #222 50%,
      #222 50%,
      #333 56.25%,
      #222 56.25%,
      #222 100%
   ) !important;
   background-size: 11.31px 11.31px !important;
}

ul {
   padding: 0;
}

@font-face {
   font-family: GothamNarrow;
   src: url("./fonts/GothamNarrow-Bold.otf");
   font-style: normal;
   font-weight: 900;
}

@font-face {
   font-family: GothamNarrow;
   src: url("./fonts/GothamNarrow-Book.otf");
   font-style: normal;
   font-weight: 500;
}

@font-face {
   font-family: GothamNarrow;
   src: url("./fonts/GothamNarrow-Medium.otf");
   font-style: normal;
   font-weight: 700;
}

@font-face {
   font-family: GothamNarrow;
   src: url("./fonts/GothamNarrow-Light.otf");
   font-style: normal;
   font-weight: 100;
}

@font-face {
   font-family: Beleren2016-Bold;
   src: url("./fonts/Beleren2016-Bold.woff");
}

.transparentBG-dark {
   background: url("./images/transparentbg.jpg") top left repeat;
   background-size: 100px;
}

.transparentBG-light {
   background: url("./images/transparentbg-light.jpg") top left repeat;
   background-size: 100px;
}
